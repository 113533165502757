export const component = async () => {
    const slick = await import('../vendor/jquery.slick');

    var $outerGallery = $('.image-gallery--cta');
    var outerGalleryOptions = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        autoplay: true,
        autoplaySpeed: 15000,
    };

    // Start gallery
    $outerGallery.slick(outerGalleryOptions);

    function initCustomControls() {
        // Add wrapper to control layout of dots
        $('.image-gallery--cta .slick-dots').wrap(
            $('<div/>', {
                class: 'image-gallery-list-dots',
            })
        );

        $('.image-gallery--cta .image-gallery-list-dots').prepend(
            '<button class="u-pause" role="presentation"><span>Pause Carousel</span><i class="fa fa-pause" aria-hidden="true"></i></button>'
        );

        // Add pause/play functionality to the gallery control
        $('.u-pause').on('click', function () {
            if ($outerGallery.hasClass('slick-pause')) {
                $outerGallery.slick('slickPlay');
                $(this).children('span').text('Pause carousel');
            } else {
                $outerGallery.slick('slickPause');
                $(this).children('span').text('Play carousel');
            }
            $outerGallery.toggleClass('slick-pause');
        });
    }

    initCustomControls();

    //Handle sidebar expand
    window.addEventListener('sidebarToggled', function () {
        $outerGallery.slick('refresh');
        initCustomControls();
    });

    //External pause
    window.addEventListener('sidebarPaused', function () {
        $outerGallery.slick('slick-pause');
    });
};
